.box {
  background: rgb(238, 250, 197);
  border: 4px solid #d4b350;
  padding: 1rem;
}
.box:hover {
  background: rgb(244, 247, 219); /* make this whatever you want */
  transform: scale(1.05);
  box-shadow: rgb(255, 149, 95) 0px 22px 70px 4px;
}
.text {
  text-align: justify;
}
.div {
  background-color: rgb(238, 250, 197);
}
